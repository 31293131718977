import * as yup from 'yup';

function hasValue(message) {
  return this.required(message).typeError(message);
}

function hasNoExtraSpaces(message) {
  return this.test({
    name: 'manual',
    message: message || 'field.error.no-extra-spaces',
    test: value => !/^\s|\s{2,}|\s$/.test(value),
  });
}
function maxFileSizeKb(limit) {
  return this.test({
    name: 'manual',
    message: { id: 'field.error.file.max-limit', values: { limit: `${limit} KB` } },
    test: file => {
      if (!file) return true;
      return file.size < limit;
    },
  });
}

function hasCorrectFileFormat(formats) {
  return this.test({
    name: 'manual',
    message: 'field.error.file.format',
    test: file => {
      if (!file) return true;

      return formats.some(format => file.extension?.endsWith(format));
    },
  });
}

yup.addMethod(yup.mixed, 'hasValue', hasValue);
yup.addMethod(yup.mixed, 'hasNoExtraSpaces', hasNoExtraSpaces);
yup.addMethod(yup.mixed, 'maxFileSizeKb', maxFileSizeKb);
yup.addMethod(yup.mixed, 'hasCorrectFileFormat', hasCorrectFileFormat);
