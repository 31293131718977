import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { useUserProfile } from 'context/auth';
import { createQueryClient } from 'utils/query';

function QueryProvider({ dehydratedState, children }) {
  const { userProfile } = useUserProfile();

  const [queryClient, setQueryClient] = useState(() =>
    createQueryClient({ enabled: Boolean(userProfile) })
  );

  useUpdateEffect(
    // When user logs in we enable requests in React Query and use previously cached values.
    // Switching enable through queryClient.setDefaultProps doesn't work
    // because refetch doesn't work as expected and some queries stay disabled.
    () =>
      setQueryClient(prevClient =>
        createQueryClient({
          enabled: Boolean(userProfile),
          queryCache: userProfile ? prevClient.getQueryCache() : undefined,
        })
      ),

    [userProfile]
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </Hydrate>
    </QueryClientProvider>
  );
}

QueryProvider.propTypes = {
  dehydratedState: PropTypes.shape(),
  children: PropTypes.node.isRequired,
};

QueryProvider.defaultProps = {
  dehydratedState: {},
};

export { QueryProvider };
