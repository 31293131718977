import { CacheProvider as EmotionCacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import ModalContainer from 'react-modal-promise';

import { ErrorBoundary } from 'components/error-boundary';
import { AlertProvider } from 'context/alert';
import { AuthProvider } from 'context/auth';
import { ProjectProvider } from 'context/current-project';
import { QueryProvider } from 'context/query-provider';
import { useAppLanguage } from 'hooks/use-app-language';
import theme from 'theme';
import { FeaturesType } from 'types';
import { INTL_FORMATS_CONFIG, GTM_EVENT_CATEGORIES } from 'utils/constants';
import { muiCache } from 'utils/mui-cache';

import { FeaturesProvider } from './feature-flag-provider';
import { GtmEventProvider } from './gtm';

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE_KEY);

export function AppProviders({ dehydratedState, locale, messages, children, features }) {
  const { datePickerLocale } = useAppLanguage();

  return (
    <GtmEventProvider category={GTM_EVENT_CATEGORIES.PAGE}>
      <EmotionCacheProvider value={muiCache}>
        <IntlProvider
          defaultLocale="en"
          formats={INTL_FORMATS_CONFIG}
          locale={locale}
          messages={messages}>
          <FeaturesProvider features={features}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                adapterLocale={locale}
                dateAdapter={AdapterDayjs}
                localeText={datePickerLocale}>
                <AuthProvider>
                  <ErrorBoundary>
                    <QueryProvider dehydratedState={dehydratedState}>
                      <ProjectProvider>
                        <AlertProvider>
                          <CssBaseline />
                          <ModalContainer />
                          {children}
                        </AlertProvider>
                      </ProjectProvider>
                    </QueryProvider>
                  </ErrorBoundary>
                </AuthProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </FeaturesProvider>
        </IntlProvider>
      </EmotionCacheProvider>
    </GtmEventProvider>
  );
}

AppProviders.propTypes = {
  dehydratedState: PropTypes.shape(),
  locale: PropTypes.string.isRequired,
  messages: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
  features: FeaturesType.isRequired,
};

AppProviders.defaultProps = {
  dehydratedState: {},
};
