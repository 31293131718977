import { useRouter } from 'next/router';
import { createContext, useState, useCallback, useMemo, useContext, useEffect } from 'react';

import { storage } from 'storage-provider';

const AuthContext = createContext();

export const AUTH_PAGES = ['/login', '/password-reset', '/password-reset/[token]'];

export function AuthProvider(props) {
  const router = useRouter();

  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const isAuthPage = AUTH_PAGES.includes(router.pathname);

    if (!userProfile) {
      const storedProfile = storage.getProfile();
      const csrfToken = storage.getToken();

      if (!storedProfile || !csrfToken) {
        if (storedProfile || csrfToken) {
          storage.clear();
        }

        if (!isAuthPage) {
          router.push(`/login?target=${encodeURIComponent(router.asPath)}`);
        }
      } else {
        setUserProfile(storedProfile);
      }
    } else if (isAuthPage) {
      router.push(router.query.target ?? '/');
    }
  }, [router, userProfile]);

  const addProfile = useCallback(newProfile => {
    setUserProfile(newProfile);
    storage.setProfile(newProfile);
  }, []);

  const removeProfile = useCallback(() => {
    setUserProfile(null);
    storage.clear();
  }, []);

  const value = useMemo(
    () => ({ userProfile, addProfile, removeProfile }),
    [addProfile, removeProfile, userProfile]
  );

  return <AuthContext.Provider value={value} {...props} />;
}

export function useUserProfile() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useUserProfile must be used within a AuthProvider.`);
  }
  return context;
}
