import { BET_FILTER_PARAM_TYPES, BET_SOURCE_TYPE, BET_TYPES } from 'templates/bets/constants';
import { SORT_BY } from 'utils/constants';
import {
  parseAsArrayOf,
  parseAsBoolean,
  parseAsDatePeriod,
  parseAsInteger,
  parseAsString,
  parseAsStringEnum,
} from 'utils/parsers';

export const BET_RESULTS = {
  NO_RESULTS: 'no_results',
  WIN: 'win',
  LOSE: 'lose',
  REFUND: 'refund',
};

export const URL_PARAMS_CONFIG = {
  [BET_FILTER_PARAM_TYPES.PLACEMENT_PERIOD]: {
    parser: parseAsDatePeriod,
    defaultValue: [],
  },
  [BET_FILTER_PARAM_TYPES.SETTLEMENT_PERIOD]: {
    parser: parseAsDatePeriod,
    defaultValue: [],
  },
  [BET_FILTER_PARAM_TYPES.TYPE]: {
    parser: parseAsArrayOf(parseAsStringEnum(BET_TYPES)),
    defaultValue: [],
  },
  [BET_FILTER_PARAM_TYPES.RESULT]: {
    parser: parseAsArrayOf(parseAsStringEnum(Object.values(BET_RESULTS))),
    defaultValue: [],
  },
  [BET_FILTER_PARAM_TYPES.SPORT_URN_ID]: {
    parser: parseAsArrayOf(parseAsString),
    defaultValue: [],
  },
  [BET_FILTER_PARAM_TYPES.SPORT_ID]: {
    parser: parseAsArrayOf(parseAsString),
    defaultValue: [],
  },
  [BET_FILTER_PARAM_TYPES.CATEGORY_URN_ID]: {
    parser: parseAsArrayOf(parseAsString),
    defaultValue: [],
  },
  [BET_FILTER_PARAM_TYPES.CATEGORY_ID]: {
    parser: parseAsArrayOf(parseAsString),
    defaultValue: [],
  },
  [BET_FILTER_PARAM_TYPES.TOURNAMENT_URN_ID]: {
    parser: parseAsArrayOf(parseAsString),
    defaultValue: [],
  },
  [BET_FILTER_PARAM_TYPES.TOURNAMENT_ID]: {
    parser: parseAsArrayOf(parseAsString),
    defaultValue: [],
  },
  [BET_FILTER_PARAM_TYPES.EVENT_URN_ID]: {
    parser: parseAsArrayOf(parseAsString),
    defaultValue: [],
  },
  [BET_FILTER_PARAM_TYPES.BET_SOURCE]: {
    parser: parseAsArrayOf(parseAsStringEnum(Object.values(BET_SOURCE_TYPE))),
    defaultValue: [],
  },
  [BET_FILTER_PARAM_TYPES.IS_AUTO_UPDATE_ENABLED]: {
    parser: parseAsBoolean,
    defaultValue: false,
  },
  [BET_FILTER_PARAM_TYPES.SORT_BY]: {
    parser: parseAsArrayOf(parseAsString),
    defaultValue: [SORT_BY.CREATED_AT.DESC],
  },
  [BET_FILTER_PARAM_TYPES.TERM]: {
    parser: parseAsString,
    defaultValue: '',
  },
  [BET_FILTER_PARAM_TYPES.STAKE_EUR_FROM]: {
    parser: parseAsInteger,
    defaultValue: '',
  },
  [BET_FILTER_PARAM_TYPES.STAKE_EUR_TO]: {
    parser: parseAsInteger,
    defaultValue: '',
  },
  [BET_FILTER_PARAM_TYPES.WIN_EUR_FROM]: {
    parser: parseAsInteger,
    defaultValue: '',
  },
  [BET_FILTER_PARAM_TYPES.WIN_EUR_TO]: {
    parser: parseAsInteger,
    defaultValue: '',
  },
};

export const QUICK_FILTERS = [
  BET_FILTER_PARAM_TYPES.TERM,
  BET_FILTER_PARAM_TYPES.IS_AUTO_UPDATE_ENABLED,
  BET_FILTER_PARAM_TYPES.SORT_BY,
];
