import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { ErrorBoundary as ErrorBoundaryWrapper } from 'react-error-boundary';

import { ErrorFallback } from 'components/error-fallback';

function ErrorBoundary({ children }) {
  const { reset } = useQueryErrorResetBoundary();
  const router = useRouter();

  return (
    <ErrorBoundaryWrapper
      FallbackComponent={ErrorFallback}
      resetKeys={[router.asPath]}
      onReset={reset}>
      {children}
    </ErrorBoundaryWrapper>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ErrorBoundary };
