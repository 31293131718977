import Script from 'next/script';
import PropTypes from 'prop-types';

export function GtmScript({ id }) {
  return (
    <Script
      dangerouslySetInnerHTML={{
        __html:
          "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':" + // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
          "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]," +
          "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
          "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);" +
          `})(window,document,'script','dataLayer','${id}')`,
      }}
      id={id}
      strategy="afterInteractive"
    />
  );
}

GtmScript.propTypes = {
  id: PropTypes.string.isRequired,
};
