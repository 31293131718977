import dayjs from 'dayjs';
import isEqual from 'lodash.isequal';
import isPlainObject from 'lodash.isplainobject';
import set from 'lodash.set';
import transform from 'lodash.transform';

import {
  BONUS_TYPES,
  BONUS_FIELD_LABELS,
  HUNTING_TYPES,
  HUNTING_SUB_TYPES,
  BONUS_FIELD_TOOLTIPS,
  PROVIDER_BY_CODE,
} from './constants';

export const sumBy = (items, key) => items.reduce((prev, item) => prev + item[key], 0);

export const isFunction = func => typeof func === 'function';

export function isNil(value) {
  return value == null;
}

export function omitDeep(object, predicate = isNil) {
  if (isNil(object)) {
    return {};
  }

  return Object.keys(object).reduce((res, key) => {
    const value = object[key];

    if (predicate(value)) {
      return res;
    }

    return {
      ...res,
      [key]: isPlainObject(value) ? omitDeep(value, predicate) : value,
    };
  }, {});
}

export function isDeepEqual(obj1, obj2, predicate = isNil) {
  return isEqual(omitDeep(obj1, predicate), omitDeep(obj2, predicate));
}

export function transformObjectKeys(object) {
  return transform(object, (transformed, val, key) => set(transformed, key, val));
}

export function getBonusSumLabel(bonusType) {
  if (bonusType === BONUS_TYPES.HUNTING || bonusType === BONUS_TYPES.LOOTBOX) {
    return BONUS_FIELD_LABELS.BONUS_SUM_PER_PLAYER;
  }

  if (bonusType === BONUS_TYPES.TOURNAMENT) {
    return BONUS_FIELD_LABELS.BONUS_SUM_TOURNAMENT_POOL;
  }

  return BONUS_FIELD_LABELS.BONUS_SUM;
}

export function getBonusSumTooltip(bonusType) {
  if (bonusType === BONUS_TYPES.FREEBET) {
    return BONUS_FIELD_TOOLTIPS.FREEBET_BONUS_TOOLTIP;
  }
  return BONUS_FIELD_TOOLTIPS.DEFAULT_BONUS_TOOLTIP;
}

export function getHuntingType(bonusType) {
  if (bonusType === BONUS_TYPES.HUNTING) {
    return HUNTING_TYPES.REGULAR;
  }

  if (HUNTING_SUB_TYPES.includes(bonusType)) {
    return bonusType;
  }

  return undefined;
}

export const entityPartWithIdRegex = /\([A-Za-z]{2}: \d+\)/;

export function getProviderCodeFromExternalId(externalId) {
  const [providerCode] = externalId.split(':');

  return providerCode !== externalId ? providerCode : undefined;
}

export function getProviderFromExternalId(externalId) {
  const providerCode = getProviderCodeFromExternalId(externalId);

  return providerCode ? PROVIDER_BY_CODE[providerCode] : undefined;
}

export function convertExternalId(id) {
  const providerCode = getProviderCodeFromExternalId(id)?.toUpperCase() || 'ID';
  // we replace non-necessary symbols with provider code or with 'ID' if provider didn't match:
  // example: sr:tournament:1 -> SR: 1
  return id.replace(/.{2}:.+:/, `${providerCode}: `);
}

export function generateDatesInPeriod(from, to) {
  const dates = [];
  const dateFrom = dayjs(from);
  const dateTo = dayjs(to);
  const dateDiff = dateTo.diff(dateFrom, 'day', true);

  for (let i = 0; i < dateDiff; i++) {
    dates.push(dateFrom.add(i, 'day'));
  }

  return dates;
}

export function generateHoursForDate(startDate) {
  const dates = [];
  const date = dayjs(startDate);

  for (let i = 0; i < 24; i++) {
    dates.push(date.set('hour', i));
  }

  return dates;
}

const generateZeroItem = (exampleItem = {}) => {
  return Object.fromEntries(Object.keys(exampleItem).map(key => [key, 0]));
};

export function prepareCumulativeDataToRender(data, period, unit = 'day') {
  const timeSeries =
    unit === 'day' ? generateDatesInPeriod(...period) : generateHoursForDate(period[0]);

  return timeSeries.reduce(
    (result, time, index) => [
      ...result,
      data.find(item => time.isSame(item.date, unit)) || {
        ...(index === 0 ? generateZeroItem(data[0]) : result[index - 1]),
        date: time.format('YYYY-MM-DD HH'),
      },
    ],
    []
  );
}

export const projectCurrenciesSelector = projectCurrencies =>
  projectCurrencies
    .sort((a, b) => a.code.localeCompare(b.code))
    .reduce(
      (acc, item) => {
        acc[item.isFiat ? 'fiatCurrencies' : 'cryptoCurrencies'].push(item);
        return acc;
      },
      { fiatCurrencies: [], cryptoCurrencies: [] }
    );

export const noop = () => {};

// Remove all props with `undefined` value because
// Next.js doesn't allow undefined values in getServerSideProps response
// https://github.com/vercel/next.js/discussions/11209
export function omitUndefinedServerSideProps(object) {
  return JSON.parse(JSON.stringify(object));
}
