import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.ENVIRONMENT_NAME,

  tracesSampleRate: process.env.NODE_ENV !== 'production' ? undefined : 1,

  // Prevent sending local development errors to Sentry
  beforeSend:
    process.env.NODE_ENV !== 'production'
      ? (event, hint) => {
          const error = hint.originalException;

          if (error.isAxiosError) {
            // Errors produced by axios contain whole request and response objects.
            // In most cases all that info is useless and only polutes log,
            // therefore we log only essential info.
            const { method, baseURL, url } = error.config;

            console.error(error.stack, { method, baseURL, url }); // eslint-disable-line no-console
          } else {
            console.error(error); // eslint-disable-line no-console
          }

          return null;
        }
      : undefined,
});
