import Cookies from 'js-cookie';

import { COOKIES, STORAGE_KEYS } from 'utils/constants';

export const storage = {
  setProfile(profile) {
    return window.localStorage.setItem(STORAGE_KEYS.USER_PROFILE, JSON.stringify(profile));
  },
  getProfile() {
    return JSON.parse(window.localStorage.getItem(STORAGE_KEYS.USER_PROFILE));
  },
  setTableConfiguration(tableId, callback) {
    const previousState = this.getTableConfiguration(tableId);

    const key = STORAGE_KEYS.TABLE_CONFIGURATION(tableId);
    const value = JSON.stringify(callback(previousState || {}));

    return window.localStorage.setItem(key, value);
  },
  getTableConfiguration(tableId) {
    const key = STORAGE_KEYS.TABLE_CONFIGURATION(tableId);
    const value = window.localStorage.getItem(key);

    return JSON.parse(value) || {};
  },
  setHiddenStatisticsColumns(newState) {
    return window.localStorage.setItem(
      STORAGE_KEYS.HIDDEN_STATISTICS_COLUMNS,
      JSON.stringify(newState)
    );
  },
  getHiddenStatisticsColumns() {
    return JSON.parse(window.localStorage.getItem(STORAGE_KEYS.HIDDEN_STATISTICS_COLUMNS)) || {};
  },
  getToken() {
    return Cookies.get(COOKIES.CSRF_TOKEN);
  },
  clear() {
    localStorage.removeItem(STORAGE_KEYS.USER_PROFILE);
    localStorage.removeItem(STORAGE_KEYS.HIDDEN_STATISTICS_COLUMNS);
    Cookies.remove(COOKIES.CSRF_TOKEN);
  },
};
