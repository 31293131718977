import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import 'dayjs/locale/pt';

import { STORAGE_KEYS } from 'utils/constants';

import { LOCALES, DEFAULT_LANGUAGE } from '../../variables';

// Don't map such locales to general language.
// For instance, 'pt-ue' will be mapped to 'pt',
// while 'pt-br' should be used as is.
const DIALECT_MAPPING_EXCEPTIONS = ['pt-br'];

const LOCALE_TO_DATA_PICKER_KEY = {
  ru: 'ruRU',
  'pt-br': 'ptBR',
};

function getGeneralLocale(value) {
  if (!value) return undefined;

  if (DIALECT_MAPPING_EXCEPTIONS.includes(value.toLowerCase())) {
    return value;
  }

  return value.split('-')[0];
}

export function useAppLanguage() {
  const router = useRouter();
  const { pathname, asPath, query } = router;

  const [datePickerLocale, setDatePickerLocale] = useState();

  useEffect(() => {
    const nextLocale = LOCALE_TO_DATA_PICKER_KEY[router.locale];
    if (nextLocale) {
      import(`@mui/x-date-pickers/locales/${nextLocale}.js`).then(data => {
        setDatePickerLocale(
          data[nextLocale].components.MuiLocalizationProvider.defaultProps.localeText
        );
      });
    } else {
      setDatePickerLocale(undefined);
    }
  }, [router.locale]);

  const setLanguage = useCallback(
    nextLang => {
      // Handles the concurrency issue with login redirect. Redirect with the wrong
      // locale can happen right after this router change.
      setTimeout(() => {
        router.push({ pathname, query }, asPath, { locale: nextLang });
      }, 0);
      localStorage.setItem(STORAGE_KEYS.LANGUAGE, nextLang);
      // Impossible to import it dynamically:
      // https://github.com/mui/material-ui/issues/30613
      dayjs().locale(nextLang);
    },
    [asPath, pathname, query, router]
  );

  const detectLanguage = useCallback(() => {
    const browserLocale = getGeneralLocale(navigator.language || navigator.userLanguage);
    const storedLocale = localStorage.getItem(STORAGE_KEYS.LANGUAGE);

    const detectedLocale = storedLocale || browserLocale;

    if (LOCALES.map(locale => locale.code).includes(detectedLocale)) {
      setLanguage(detectedLocale);
    } else {
      setLanguage(DEFAULT_LANGUAGE);
    }
  }, [setLanguage]);

  return { setLanguage, detectLanguage, datePickerLocale };
}
