import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);

export function isValidDate(date) {
  const dateObject = new Date(date);

  return (
    Object.prototype.toString.call(dateObject) === '[object Date]' &&
    !Number.isNaN(dateObject.getTime())
  );
}

export function getMaxDate(dates = []) {
  const validDates = dates.filter(isValidDate).map(dayjs);

  return validDates.length > 0 ? dayjs(Math.max.apply(null, validDates)) : null;
}

export function areDatesEqual(date1, date2) {
  if (isValidDate(date1) && isValidDate(date2)) {
    return new Date(date1).getTime() === new Date(date2).getTime();
  }

  return false;
}

export function getAge(birthDate) {
  return dayjs(birthDate).isValid() ? dayjs().diff(birthDate, 'year') : null;
}

export function getTimeZoneOffset(locale, timeZone) {
  return Intl.DateTimeFormat(locale, { timeZone, timeZoneName: 'shortOffset' })
    .formatToParts()
    .find(part => part.type === 'timeZoneName').value;
}
