import { SnackbarProvider as NotistackProvider, useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useContext, useCallback } from 'react';

import { Alert } from 'components/alert';

const AlertContext = createContext();

function AlertProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();

  const showAlert = useCallback(
    ({ title, message, messageValues, severity, action, options = {} }) =>
      enqueueSnackbar(message, {
        content: id => (
          <Alert
            action={action}
            id={id}
            message={message}
            messageValues={messageValues}
            severity={severity}
            title={title}
          />
        ),
        ...options,
      }),
    [enqueueSnackbar]
  );

  const showSuccessAlert = useCallback(
    options => showAlert({ severity: 'success', ...options }),
    [showAlert]
  );

  const showErrorAlert = useCallback(
    options => showAlert({ severity: 'error', ...options }),
    [showAlert]
  );

  const createAlertHandler = useCallback(
    ({ callback, ...props }) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCallback(() => {
        showAlert(props);

        if (callback) callback();
      }, [callback, props]),
    [showAlert]
  );

  const value = useMemo(
    () => ({ showSuccessAlert, showErrorAlert, createAlertHandler }),
    [createAlertHandler, showErrorAlert, showSuccessAlert]
  );

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
}

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function NotistackAlertProvider(props) {
  return (
    <NotistackProvider
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      autoHideDuration={5000}>
      <AlertProvider {...props} />
    </NotistackProvider>
  );
}

export { NotistackAlertProvider as AlertProvider };

export function useAlert() {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error(`useAlert must be used within a AlertProvider.`);
  }
  return context;
}
