import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { ErrorTemplate } from 'components/error-template';
import { useUserProfile } from 'context/auth';
import { HTTP_CODES } from 'utils/constants';
import { captureException } from 'utils/errors';

export function ErrorFallback({ error, resetErrorBoundary }) {
  const { removeProfile } = useUserProfile();

  const isUnauthorizedError = error.response?.status === HTTP_CODES.UNAUTHORIZED;

  useEffect(() => {
    if (!isUnauthorizedError) {
      captureException(error);
    }
  }, [error, isUnauthorizedError]);

  if (isUnauthorizedError) {
    removeProfile();
    resetErrorBoundary();

    return null;
  }

  return <ErrorTemplate errorCode={error.response?.status} onReset={resetErrorBoundary} />;
}

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    response: PropTypes.shape({
      status: PropTypes.number.isRequired,
    }),
  }).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};
