import Head from 'next/head';
import { useIntl } from 'react-intl';

export function PageHead() {
  const { formatMessage } = useIntl();
  return (
    <Head>
      <title>{formatMessage({ id: `meta.title` })}</title>
      <meta content="minimum-scale=1, initial-scale=1, width=device-width" name="viewport" />
      <meta content="noindex" name="robots" />
    </Head>
  );
}
