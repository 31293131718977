import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { alertClasses } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { forwardRef, useCallback } from 'react';

import { Text, t } from 'components/formatted-message';
import { makeStyles } from 'utils/make-styles';

const useStyles = makeStyles()(
  theme => ({
    root: {
      [`&.${alertClasses.filledSuccess}, &.${alertClasses.filledWarning}, &.${alertClasses.filledInfo}`]:
        {
          color: theme.palette.common.white,
        },
      [`.${alertClasses.action}`]: {
        paddingTop: 0,
      },
      width: '100%',
      alignItems: 'center',
      boxShadow: theme.shadows[6],
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        maxWidth: 600,
      },
    },
  }),
  { name: 'bo-Alert' }
);

const Alert = forwardRef(
  (
    { id, className, title, message, messageValues, severity, variant, closable, action, onClose },
    ref
  ) => {
    const { classes, cx } = useStyles();
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
      onClose?.();
    }, [id, closeSnackbar, onClose]);

    return (
      <MuiAlert
        ref={ref}
        action={
          action ||
          (closable && (
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleDismiss}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          ))
        }
        className={cx(classes.root, className)}
        severity={severity}
        variant={variant}>
        {title && <AlertTitle>{t(title)}</AlertTitle>}
        <Text color="inherit" id={message} values={messageValues} variant="body2" />
      </MuiAlert>
    );
  }
);

Alert.displayName = 'Alert';

Alert.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  messageValues: PropTypes.shape({}),
  severity: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  closable: PropTypes.bool,
  action: PropTypes.element,
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  className: undefined,
  title: null,
  messageValues: {},
  variant: 'filled',
  closable: true,
  action: undefined,
  onClose: undefined,
};

export { Alert };
