import Big from 'big.js';

import { CURRENCY_CONFIG, SUBUNITS } from 'utils/constants';

export function getFixedDigits(number, limit) {
  const numberDecimalLength = number.toString().split('.')[1]?.length;

  if (numberDecimalLength > 0) {
    return numberDecimalLength >= limit ? limit : numberDecimalLength;
  }

  return limit;
}

export const divideValues = (a, b) => new Big(a).div(b);

export const multiplyValues = arr =>
  arr.length > 0
    ? Number(arr.reduce((result, number) => result.times(number || 0), new Big(1)))
    : 0;

export function formatNumber(number, options = {}) {
  const { subunits, decimalDigitsCount } = options;

  if (number === '' || Number.isNaN(Number(number))) {
    return undefined;
  }

  const bigNumber = divideValues(number || 0, subunits || 1);

  return Number(bigNumber).toFixed(getFixedDigits(bigNumber, decimalDigitsCount));
}

export function isValidNumber(value) {
  return /^\d+$/.test(value);
}

export function formatCurrency(number, options = {}) {
  const formattedNumber = formatNumber(number, options);

  if (options?.currency) {
    return `${formattedNumber} ${options.currency}`;
  }

  return formattedNumber;
}

export function formatCurrencySubunits(number, options) {
  const formattedNumber = formatNumber(number, {
    decimalDigitsCount: CURRENCY_CONFIG.DECIMAL_DIGITS_COUNT,
    ...options,
    subunits: options?.subunits || SUBUNITS.CURRENCY,
  });

  if (options?.currency) {
    return `${formattedNumber} ${options.currency}`;
  }

  return formattedNumber;
}

export function formatEuroCent(number, options = {}) {
  return formatCurrency(number, {
    ...options,
    subunits: SUBUNITS.CURRENCY,
    decimalDigitsCount: CURRENCY_CONFIG.DECIMAL_DIGITS_COUNT,
    currency: CURRENCY_CONFIG.CURRENCY,
  });
}

export function formatOdds(odds) {
  if (!odds) return odds;

  return Number(`${Math.round(`${odds}e-1`)}e-2`).toFixed(2);
}

export const calculateTotalOdds = (odds, bonusOdds) => {
  return multiplyValues([
    ...odds.map(odd => divideValues(odd, SUBUNITS.ODDS)),
    bonusOdds > 0 ? divideValues(bonusOdds, SUBUNITS.ODDS) : 1,
    SUBUNITS.ODDS,
  ]);
};
