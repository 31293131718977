import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

// T stands for translate
// can be used as tagged template when passed string has no variables
export function t(id, values) {
  return <FormattedMessage id={id} values={{ ...values, b: (...chunks) => <b>{chunks}</b> }} />;
}

function TextUI({ id, values, ...typographyProps }) {
  return (
    <Typography {...typographyProps}>
      <FormattedMessage id={id} values={{ ...values, b: (...chunks) => <b>{chunks}</b> }} />
    </Typography>
  );
}

TextUI.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.shape({}),
};

TextUI.defaultProps = {
  values: undefined,
};

export const Text = memo(TextUI);
