import { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useGtmEventContext } from 'context/gtm';
import { GtmEventPageType } from 'types';

function GtmEventsTrackerUI({ page }) {
  const { trackEvent } = useGtmEventContext();
  const { locale } = useIntl();

  useEffect(() => {
    trackEvent({
      page,
      locale,
      screenSize: `${window.screen.width}x${window.screen.height}`,
    });
  }, [trackEvent, locale, page]);

  return null;
}

GtmEventsTrackerUI.propTypes = {
  page: GtmEventPageType,
};

GtmEventsTrackerUI.defaultProps = {
  page: undefined,
};

export const GtmEventsTracker = memo(GtmEventsTrackerUI);
