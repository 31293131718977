import { useQuery, useQueries, useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentProjectKey } from 'context/current-project';
import {
  deserializeCurrencies,
  deserializeProjectSettings,
  deserializeProjectMinStake,
} from 'utils/deserialize';
import { createQueryKeys, createPaginatedDataHook } from 'utils/query';
import { serializeUserSettingsMinStake } from 'utils/serialize';

import { apiClient } from './client-sportsbook-api';

export const projectQueriesKeys = createQueryKeys('projects');

export const useProject = (projectKey, config = {}) =>
  useQuery(projectQueriesKeys.detail(projectKey), () => apiClient.getProject(projectKey), {
    ...config,
    enabled: Boolean(projectKey) && (config.enabled ?? true),
  });

export const useProjects = createPaginatedDataHook({
  useData: ({ params = {}, config = {} }) =>
    useQuery(projectQueriesKeys.list(params), () => apiClient.getProjects(params), config),
});

export const useUpdateProjectSettings = ({ projectKey, config = {} }) => {
  return useMutation(
    ({ payload }) => apiClient.updateProjectSettings({ projectKey, payload }),
    config
  );
};

export const useUpdateProject = ({ projectKey, config = {} }) => {
  const queryClient = useQueryClient();

  return useMutation(({ payload }) => apiClient.updateProject({ projectKey, payload }), {
    ...config,
    onSuccess: project => {
      config.onSuccess?.(project);

      queryClient.setQueryData(projectQueriesKeys.detail(project.key), project);

      return queryClient.invalidateQueries(projectQueriesKeys.lists());
    },
  });
};

export const useProjectSettings = ({ projectKey, config = {} }) => {
  return useQuery(
    ['projects', projectKey, 'settings'],
    () =>
      apiClient.axios
        .get(`/projects/${projectKey}/settings`, {
          headers: { 'X-Project': projectKey },
        })
        .then(response => deserializeProjectSettings(response.data)),
    {
      ...config,
      enabled: Boolean(projectKey) && (config.enabled ?? true),
    }
  );
};

export const useProjectsSettings = ({ projectKeys, config = {} }) => {
  const projectsSettingsData = useQueries({
    queries: projectKeys.map(key => ({
      queryKey: ['projects', key, 'settings'],
      queryFn: () => apiClient.getProjectSettings(key),
      ...config,
      enabled: Boolean(projectKeys.length) && (config.enabled ?? true),
    })),
  });

  return projectsSettingsData;
};

export const useCurrentProjectSettings = (config = {}) => {
  const projectKey = useCurrentProjectKey();

  return useProjectSettings({ projectKey, config });
};

export const useCurrentProjectLocales = (config = {}) => {
  const projectKey = useCurrentProjectKey();

  return useQuery(
    ['projects', projectKey, 'settings'],
    () =>
      apiClient.axios
        .get(`/projects/${projectKey}/settings`, {
          headers: { 'X-Project': projectKey },
        })
        .then(response => deserializeProjectSettings(response.data)),
    {
      select: settings => settings.locales,
      ...config,
      enabled: Boolean(projectKey) && (config.enabled ?? true),
    }
  );
};

export const useCurrentProjectCurrencies = (config = {}) => {
  const projectKey = useCurrentProjectKey();

  return useQuery(
    ['projects', projectKey, 'currencies'],
    () =>
      apiClient.axios
        .get(`/projects/${projectKey}/currencies`, {
          headers: { 'X-Project': projectKey },
        })
        .then(response => deserializeCurrencies(response.data)),
    {
      ...config,
      enabled: Boolean(projectKey) && (config.enabled ?? true),
    }
  );
};

export const useCurrentProjectUserSettingsMinStake = (config = {}) => {
  const projectKey = useCurrentProjectKey();

  return useQuery(
    ['projects', projectKey, 'user-settings', 'min-stake'],
    () => {
      return apiClient.axios
        .get(`/projects/${projectKey}/user_settings/min_stake`, {
          headers: { 'X-Project': projectKey },
        })
        .then(response => deserializeProjectMinStake(response.data));
    },
    {
      ...config,
      enabled: Boolean(projectKey) && (config.enabled ?? true),
    }
  );
};

export function useUpdateCurrentProjectUserSettingsMinStake(config = {}) {
  const queryClient = useQueryClient();
  const projectKey = useCurrentProjectKey();

  return useMutation(
    data =>
      apiClient.axios.put(
        `/projects/${projectKey}/user_settings/min_stake`,
        serializeUserSettingsMinStake(data),
        {
          headers: {
            'X-Project': projectKey,
          },
        }
      ),
    {
      ...config,
      onSuccess: () => {
        config.onSuccess?.();

        return queryClient.invalidateQueries([
          'projects',
          projectKey,
          'user-settings',
          'min-stake',
        ]);
      },
    }
  );
}
