import { captureException as sentryCaptureException } from '@sentry/nextjs';

export function captureException(err, ctx) {
  return sentryCaptureException(err, scope => {
    if (ctx?.extra) {
      Object.entries(ctx.extra).forEach(([key, value]) => scope.setExtra(key, value));
    }
  });
}

export function isServerError(error) {
  return error.response?.status >= 500;
}
