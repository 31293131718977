import PropTypes from 'prop-types';
import { createContext, memo, useContext, useMemo } from 'react';

import { GtmEventCategoryType } from 'types';

const GtmEventContext = createContext();

function GtmEventProviderUI({ children, category }) {
  const providerValue = useMemo(
    () => ({
      trackEvent: eventNameOrProps => {
        const eventProps =
          typeof eventNameOrProps === 'string' ? { event: eventNameOrProps } : eventNameOrProps;

        window.dataLayer?.push({ category, ...eventProps });
      },
    }),
    [category]
  );

  return <GtmEventContext.Provider value={providerValue}>{children}</GtmEventContext.Provider>;
}

export function useGtmEventContext() {
  const context = useContext(GtmEventContext);

  if (context === undefined) {
    throw new Error(`useGtmEventContext must be used within a GtmEventProvider.`);
  }

  return context;
}

GtmEventProviderUI.propTypes = {
  children: PropTypes.node.isRequired,
  category: GtmEventCategoryType,
};

GtmEventProviderUI.defaultProps = {
  category: undefined,
};

export const GtmEventProvider = memo(GtmEventProviderUI);
