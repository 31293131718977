import PropTypes from 'prop-types';
import { createContext, memo, useContext } from 'react';

import { FeaturesType } from 'types';

export const FeaturesContext = createContext();

function FeaturesProviderUI({ features, children }) {
  return <FeaturesContext.Provider value={features}>{children}</FeaturesContext.Provider>;
}

FeaturesProviderUI.propTypes = {
  features: FeaturesType.isRequired,
  children: PropTypes.node.isRequired,
};

export function useFeatures() {
  const features = useContext(FeaturesContext);

  if (features === undefined) {
    throw new Error(`useFeatures must be used within a FeaturesContext.`);
  }

  return features;
}

export const FeaturesProvider = memo(FeaturesProviderUI);
