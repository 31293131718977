import dayjs from 'dayjs';
import isEmpty from 'lodash.isempty';
import { createParser } from 'nuqs';

import { storage } from 'storage-provider';

export {
  parseAsArrayOf,
  parseAsString,
  parseAsStringEnum,
  parseAsBoolean,
  parseAsInteger,
  parseAsIsoDateTime,
} from 'nuqs';

function getUserTimezone() {
  const userProfile = storage.getProfile();

  return userProfile?.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const parseAsDatePeriod = createParser({
  parse(value) {
    const [from, to] = value.split(',');

    const timezone = getUserTimezone();

    const fromDate = dayjs(from).tz(timezone).utc();
    const toDate = dayjs(to).tz(timezone).utc();

    if (!fromDate.isValid() || !toDate.isValid()) return null;

    return [from, to];
  },
  serialize(value) {
    const timezone = getUserTimezone();

    if (isEmpty(value)) return null;

    return value.map(date => dayjs(date).tz(timezone).toISOString()).join(',');
  },
});
