import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { GtmEventsTracker } from 'components/gtm-events-tracker';
import { GtmScript } from 'components/gtm-script';
import { PageHead } from 'components/page-head';
import { AppProviders } from 'context/root';
import { useAppLanguage } from 'hooks/use-app-language';
import { GtmEventPageType, FeaturesType } from 'types';

import 'styles.css';

// Import yup to make sure custom validators are available.
import 'utils/yup';
// Import date utils to make sure dayjs plugins are available.
import 'utils/date';

const noLayout = page => page;

const isBrowser = typeof window !== 'undefined';

export default function SportsbookBackofficeApp({ Component: Page, pageProps, appConfig }) {
  const { detectLanguage } = useAppLanguage();
  const { locale } = useRouter();

  const config = isBrowser
    ? window.__BACKOFFICE_CONFIG__ // eslint-disable-line no-underscore-dangle
    : appConfig;
  const [messages, setMessages] = useState(() => config.messages);

  const { getLayout = noLayout } = Page;

  useEffectOnce(() => {
    detectLanguage();
  }, [detectLanguage]);

  useEffect(() => {
    import(`../lang/${locale}`).then(setMessages);
  }, [locale]);

  return (
    <AppProviders
      dehydratedState={pageProps.dehydratedState}
      features={config.features}
      locale={locale}
      messages={messages}>
      <GtmScript id={process.env.GTM_ID} />
      <PageHead />
      <GtmEventsTracker page={pageProps.eventPage} />
      {getLayout(<Page {...pageProps} />, pageProps)}
    </AppProviders>
  );
}

SportsbookBackofficeApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.shape({
    dehydratedState: PropTypes.shape({}),
    eventPage: GtmEventPageType,
  }),
  appConfig: PropTypes.exact({
    features: FeaturesType.isRequired,
    messages: PropTypes.shape().isRequired,
  }),
};

SportsbookBackofficeApp.defaultProps = {
  pageProps: {},
  appConfig: undefined,
};
