import { QueryClient } from '@tanstack/react-query';
import isEqual from 'lodash.isequal';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { usePrevious } from 'react-use';

import { isServerError } from 'utils/errors';

export const createQueryKeys = key => ({
  all: [key],
  lists: () => [key, 'list'],
  list: filters => [key, 'list', filters],
  details: () => [key, 'detail'],
  detail: id => [key, 'detail', id],
  path: (...args) => [key, ...args],
});

export const serializeSortModel = sortModel =>
  sortModel.map(({ field, sort }) => `${field}:${sort}`);

const useGetConfig = (initialConfig, defaultConfig) => {
  const [config, setConfig] = useState(() => ({
    initialData: {
      data: [],
      pagination: { page: 1, lastPage: 1, total: 0, limit: 25 },
    },
    ...defaultConfig,
    ...initialConfig,
  }));

  const prevInitialConfig = usePrevious(initialConfig);

  useEffect(() => {
    if (prevInitialConfig && !isEqual(initialConfig, prevInitialConfig)) {
      setConfig(oldConfig => ({ ...oldConfig, ...initialConfig }));
    }
  }, [initialConfig, prevInitialConfig]);

  return { config, setConfig };
};

export function createPaginatedDataHook({ useData, defaultParams = {}, defaultConfig = {} }) {
  return function usePaginatedData({ initialParams = {}, config: initialConfig = {} } = {}) {
    const [params, setParams] = useState(() => ({
      page: 1,
      limit: 25,
      sortBy: [],
      ...defaultParams,
      ...initialParams,
    }));

    const prevInitialParams = usePrevious(initialParams);
    useEffect(() => {
      if (prevInitialParams && !isEqual(initialParams, prevInitialParams)) {
        setParams(oldParams => ({ ...oldParams, ...initialParams, page: 1 }));
      }
    }, [initialParams, prevInitialParams]);

    const { config } = useGetConfig(initialConfig, defaultConfig);

    const {
      data: { data, pagination },
      ...restHooksData
    } = useData({
      params,
      config,
    });

    const setPaginationModel = useCallback(
      paginationModel => {
        const { page, pageSize } = paginationModel;

        if (pageSize && pageSize !== params.limit) {
          setParams(oldParams => ({ ...oldParams, page: 1, limit: pageSize }));
        } else {
          setParams(oldParams => ({ ...oldParams, page: page + 1 }));
        }
      },
      [params]
    );

    const setSortModel = useCallback(sortModel => {
      setParams(prevParams => ({ ...prevParams, sortBy: serializeSortModel(sortModel) }));
    }, []);

    const sortModel = useMemo(
      () =>
        params.sortBy.map(sortByItem => {
          const [field, sort] = sortByItem.split(':');

          return { field, sort };
        }),
      [params.sortBy]
    );

    return {
      data,
      pagination,
      ...restHooksData,
      params,
      sortModel,
      setParams,
      setPaginationModel,
      setSortModel,
    };
  };
}

export function invalidateProfiles({ keys, queryClient, profiles, historyPath }) {
  return Promise.all(
    profiles.flatMap(profile => {
      const parentGlobalId = profile.hierarchy?.[0];

      return [
        queryClient.invalidateQueries(keys.detail(parentGlobalId ?? profile.globalId)),
        queryClient.invalidateQueries(
          keys.path(historyPath, { projectKey: profile.project, globalId: profile.globalId })
        ),
      ];
    })
  );
}

export function createQueryClient({ enabled, queryCache } = {}) {
  return new QueryClient({
    queryCache,
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        useErrorBoundary: true,
        enabled,
      },
      mutations: {
        useErrorBoundary: isServerError,
      },
    },
  });
}
