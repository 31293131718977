import { useRouter } from 'next/router';
import { createContext, useState, useEffect, useCallback, useMemo, useContext } from 'react';

import { useProjects } from 'api/project';
import { SORT_BY } from 'utils/constants';

const ProjectContext = createContext();

const FALLBACK_PROJECT_KEY_BY_ENVIRONMENT_NAME = {
  develop: 'sportsbook_dev',
  staging: 'sportsbook_demo',
};

export const PROJECTS_FETCH_PARAMS = { page: 1, limit: 100, sortBy: [SORT_BY.NAME.ASC] };

export function ProjectProvider(props) {
  const router = useRouter();
  const {
    query: { projectKey },
  } = router;

  const [currentProject, setProject] = useState(null);

  const { data: projects } = useProjects({
    initialParams: PROJECTS_FETCH_PARAMS,
  });

  useEffect(() => {
    if (projects.length > 0) {
      const projectKeyToLookUp =
        projectKey ??
        currentProject?.key ??
        FALLBACK_PROJECT_KEY_BY_ENVIRONMENT_NAME[process.env.ENVIRONMENT_NAME];

      const projectByKey = projects.find(project => project.key === projectKeyToLookUp);
      const newProject = projectByKey ?? projects[0];

      if (newProject.key !== currentProject?.key) {
        setProject(newProject);
        if (projectKey) {
          router.push({
            query: { ...router.query, projectKey: newProject.key },
          });
        }
      }
    }
  }, [currentProject, projectKey, projects, router]);

  const onProjectChange = useCallback(
    newProject => {
      if (projectKey) {
        router.push({ query: { ...router.query, projectKey: newProject.key } });
      } else {
        setProject(newProject);
      }
    },
    [projectKey, router]
  );

  const value = useMemo(() => [currentProject, onProjectChange], [currentProject, onProjectChange]);

  return <ProjectContext.Provider value={value} {...props} />;
}

export function useCurrentProject() {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error(`useCurrentProject must be used within a ProjectContext.`);
  }
  return context;
}

export function useCurrentProjectKey() {
  const [project] = useCurrentProject();
  const { key: projectKey } = project ?? {};

  return projectKey;
}
