import { createTheme } from '@mui/material';
import { blue, red, grey, green, lightGreen } from '@mui/material/colors';

const baseTheme = createTheme();

const palette = {
  mode: 'light',
  primary: {
    main: '#02A770',
    blue: blue.A400,
    red: 'red',
    contrast: '#fff',
    dark: '#067E39',
    light: '#19B95D',
  },
  secondary: {
    extraLight: grey[300],
    light: 'rgba(0,0,0,0.38)',
    main: '#2979FF',
    dark: '#1755E7',
  },
  success: {
    light: lightGreen[50],
    main: green[500],
    dark: '#02a77014',
  },
  error: {
    main: red[500],
    secondary: red[300],
    tertiary: red[400],
  },
  info: {
    main: blue[500],
  },
  warning: {
    light: '#F08934',
    main: '#ED6C02',
    dark: '#C77700',
  },
  highlight: {
    main: '#FFB400',
  },
  background: {
    default: '#fff',
    lightGrey: '#FAFAFA',
    darkGrey: '#F2F6F9',
  },
  text: {
    secondary: 'rgba(0, 0, 0, 0.54)',
  },
  table: {
    header: '#EDF0F2',
    headerBorder: `1px solid ${grey[500]}`,
    border: `1px solid ${grey[200]}`,
    body: '#fff',
    rowOdd: '#F7F9FA',
    rowEven: '#fff',
    rowHover: '#f2f2f2',
    footer: '#fff',
  },
};

function font(fontSize, lineHeight, fontWeight, letterSpacing) {
  return { fontSize, lineHeight: `${lineHeight}px`, fontWeight, letterSpacing };
}

const typography = {
  h1: font(32, 40, baseTheme.typography.fontWeightBold, 0),
  h2: font(26, 32, baseTheme.typography.fontWeightBold, 0),
  h3: font(20, 30, baseTheme.typography.fontWeightBold, 0),
  h4: font(34, 34, baseTheme.typography.fontWeightRegular, 0.25),
  h5: font(24, 24, baseTheme.typography.fontWeightRegular, 0),
  h6: font(16, 24, baseTheme.typography.fontWeightBold, 0.15),
  subtitle1: font(14, 21, baseTheme.typography.fontWeightBold, 0.1),
  subtitle2: font(12, 16, baseTheme.typography.fontWeightBold, 0.1),
  body1: font(16, 24, baseTheme.typography.fontWeightRegular, 0.01),
  body2: font(14, 20, baseTheme.typography.fontWeightRegular, 0.01),
  button: font(14, 24, baseTheme.typography.fontWeightBold, 0.2),
  caption: font(12, 16, baseTheme.typography.fontWeightRegular, 0.02),
  overline: font(12, 30, baseTheme.typography.fontWeightRegular, 0.04),
  inputLabel: font(12, 20, baseTheme.typography.fontWeightRegular, 0.15),
  inputText: font(14, 24, baseTheme.typography.fontWeightRegular, 0.15),
  tooltip: font(10, 12, baseTheme.typography.fontWeightRegular, 0.15),
};
const inputSmallHeight = 40;

const theme = createTheme({
  spacing: 4,
  palette,
  typography,
  breakpoints: {
    values: {
      xxs: 0,
      xs: 460,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  border: {
    base: `2px solid ${palette.secondary.extraLight}`,
    thin: `1px solid ${palette.secondary.extraLight}`,
  },
  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-outlinedSuccess': {
            borderColor: palette.primary.main,
            color: palette.primary.main,
            '& .MuiSvgIcon-root': {
              color: palette.primary.main,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',

          '&.MuiButton-sizeMedium': {
            height: inputSmallHeight,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...typography.body2,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        dayDisabled: {
          color: 'rgba(0, 0, 0, 0.38)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.disabled && {
            backgroundColor: grey[100],
          }),
        }),
        sizeSmall: {
          minHeight: inputSmallHeight,
        },
        inputSizeSmall: {
          fontSize: typography.inputText.fontSize,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        sizeSmall: {
          fontSize: typography.inputText.fontSize,
        },
        asterisk: {
          color: palette.error.main,
          '&$error': {
            color: palette.error.main,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        panel: {
          '.MuiDataGrid-panelHeader': {
            paddingTop: 4,
            paddingLeft: 20,
            paddingRight: 20,
          },
          '.MuiDataGrid-panelContent': {
            paddingTop: 8,
            paddingLeft: 8,
            paddingRight: 8,
          },
          '.MuiDataGrid-panelFooter': {
            paddingLeft: 12,
            paddingRight: 12,
            paddingBottom: 4,
          },
          '.MuiDataGrid-columnsPanelRow:not(:last-of-type):not(:has(.Mui-disabled))': {
            paddingBottom: 16,
          },
          '.MuiDataGrid-paper': {
            '.MuiFormControlLabel-root.Mui-disabled': {
              display: 'none',
            },
          },
        },
        cell: {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&.MuiInputBase-sizeSmall': {
            paddingRight: 34,
          },
        },
        tag: {
          '&.MuiChip-root': {
            background: palette.background.default,
            border: '1px solid currentColor',
            '&.MuiChip-colorDefault': {
              color: palette.primary.main,
              '& .MuiSvgIcon-root': {
                color: palette.primary.main,
              },
            },
            '&.MuiChip-colorSuccess': {
              color: palette.primary.main,
              '& .MuiSvgIcon-root': {
                color: palette.primary.main,
              },
            },
            '&.MuiChip-colorError': {
              color: palette.error.main,
              '& .MuiSvgIcon-root': {
                color: palette.error.main,
              },
            },
          },
          '&:not(.MuiChip-root)': {
            color: palette.text.secondary,
          },
        },
        option: {
          ...typography.body2,
        },
        noOptions: {
          ...typography.body2,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 16px',
        },
        content: {
          margin: '16px 0',

          '&.Mui-expanded': {
            margin: '16px 0',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: `0 16px 24px`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          whiteSpace: 'break-spaces',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          ...typography.h3,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        light: {
          borderBottomColor: grey[100],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 300,
          ...typography.tooltip,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${grey[300]}`,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: '4px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.Mui-disabled fieldset': {
            borderStyle: 'dotted',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          ...typography.caption,
          color: palette.text.secondary,
        },
        select: {
          ...typography.caption,
          lineHeight: 'inherit',
        },
        displayedRows: {
          ...typography.caption,
        },
      },
    },
  },
});

export default theme;
