export const HTTP_CODES = {
  OK: 200,
  MOVED_PERMANENTLY: 301,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
};

export const AUTH_STATUS = {
  UNKNOWN: 'UNKNOWN',
  PENDING: 'PENDING',
  LOGGED_IN: 'LOGGED_IN',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
};

export const STORAGE_KEYS = {
  USER_PROFILE: 'user-profile',
  HIDDEN_STATISTICS_COLUMNS: 'hidden-statistic-columns',
  STATISTIC_TABS: 'statistic-tabs',
  TABLE_CONFIGURATION: id => `${id}-table-configuration`,
  LANGUAGE: 'language',
};

export const COOKIES = {
  CSRF_TOKEN: 'csrf_token',
  AUTH_TOKEN: 'auth_token',
};

export const PROJECT_CDN_URL = `${process.env.CDN_URL}/backoffice`;
export const LOGO_URL = `${PROJECT_CDN_URL}/logo.svg`;
export const COUNTRY_ICONS_CDN = `${process.env.CDN_URL}/flags/{code}.png`;
export const LANGUAGE_ICONS_CDN = `${process.env.CDN_URL}/locale-icons/{code}.png`;

export const BONUS_ACTIVATION_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const BONUS_INTERNAL_STATUSES = {
  NOT_STARTED: 'not-started',
  STARTED: 'started',
  FINISHED: 'finished',
};

export const DATE = 'date';
export const TIME = 'time';
export const DATE_TIME = 'date_time';
export const DATE_TIME_SHORT = 'date_time_short';
export const CURRENCY = 'currency';
export const CURRENCY_SHORT = 'currency_short';
export const CURRENCY_ONLY_DIGITS = 'currency_only_digits';
export const PERCENT = 'percent';
export const PERCENT_SHORT = 'percent_short';

export const INTL_FORMATS_CONFIG = {
  number: {
    [CURRENCY]: {
      style: 'currency',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 10,
    },
    [CURRENCY_SHORT]: {
      style: 'currency',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 0,
    },
    [CURRENCY_ONLY_DIGITS]: {
      style: 'currency',
      currencyDisplay: 'none',
    },
    [PERCENT]: {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    [PERCENT_SHORT]: {
      style: 'percent',
      maximumFractionDigits: 0,
    },
  },
};

export const REPORT_NAMES = {
  METRICS: 'metrics',
  METRICS_BY_SPORT: 'metrics_by_sport',
  METRICS_BY_BET_TYPE: 'metrics_by_bet_type',
  METRICS_BY_DEVICE: 'metrics_by_customer_device',
  METRICS_BY_COUNTRY: 'metrics_by_customer_country',
  METRICS_BY_CURRENCY: 'metrics_by_currency',
  METRICS_BY_GROUP: 'metrics_by_customer_group',
  METRICS_BY_STAG: 'metrics_by_customer_s_tag',
  METRICS_BY_PROJECT: 'metrics_by_projects',
  CUMULATIVE_METRICS_BY_TIME: 'cumulative_metrics_by_time',
  PLAYERS: 'top_players',
  EVENTS: 'top_events',
  TOURNAMENTS: 'top_tournaments',
  BONUSES_GENERAL_STATISTICS: 'bonuses_general_statistics',
  FREEBET_BONUSES_GENERAL_STATISTICS: 'freebet_bonuses_general_statistics',
  FREEBET_BONUSES_BOOSTER_STATISTICS: 'freebet_bonuses_booster_statistics',
};

export const PERMISSIONS = {
  WRITE: 'write',
  READ: 'read',
  MANAGE_USERS: 'manage_users',
  MANUAL_RECALCULATION: 'manual_recalculation',
  VALUEDATOR: 'valuedator',
};

export const AUTH_ERRORS = {
  WRONG_DATA: 100,
  INACTIVE: 101,
  BLOCKED: 102,
  FROZEN: 104,
  TOTP: 120,
  WRONG_TOTP: 110,
  PASSWORD_EXPIRED: 111,
  PASSWORD_REUSED: 112,
};

export const USER_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  FROZEN: 'frozen',
  BLOCKED: 'blocked',
};

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

const createSortValue = field => ({
  ASC: `${field}:${SORT_DIRECTION.ASC}`,
  DESC: `${field}:${SORT_DIRECTION.DESC}`,
});

export const SORT_BY = {
  NAME: createSortValue('name'),
  DEPARTMENT: createSortValue('department'),
  ACTIVATED_AT: createSortValue('activated_at'),
  CREATED_AT: createSortValue('created_at'),
  PROJECT: createSortValue('project'),
  ACTION: createSortValue('action'),
  ACTION_AT: createSortValue('action_at'),
  OBJECT: createSortValue('object'),
  EMAIL: createSortValue('email'),
  REGISTERED_AT: createSortValue('registered_at'),
  PLAYER_ID: createSortValue('player_id'),
  STAKE: createSortValue('stake'),
  SETTLE_TIME: createSortValue('settle_time'),
};

export const BONUS_TYPES = {
  COMBOBOOST: 'comboboost',
  FREEBET: 'freebet',
  FREEBET_NO_RISK: 'freebet_no_risk',
  FREEBET_ALL_WIN: 'freebet_all_win',
  FREEBET_ONLY_WIN: 'freebet_only_win',
  HUNTING: 'hunting',
  LOOTBOX: 'lootbox',
  TOURNAMENT: 'tournament',
  JACKPOT: 'jackpot',
};

export const TRIGGER_NAMES = {
  NO_ISSUANCE_EVENT: 'no_issuance_event',
  STAYING_IN_GROUP: 'staying_in_group',
  ENTERING_IN_GROUP: 'entering_in_group',
  STAYING_ENTERING_IN_GROUP: 'staying_entering_in_group',
  ON_BIRTHDAY: 'on_birthday',
  ON_DEPOSIT: 'on_deposit',
  AFTER_MAKING_BETS: 'after_making_bets',
  ON_ENTER_PROMOCODE: 'on_enter_promocode',
  ON_REGISTRATION: 'on_registration',
};

export const FREEBET_TYPES = {
  NO_RISK: 'no_risk',
  ALL_WIN: 'all_win',
  ONLY_WIN: 'only_win',
};

export const HUNTING_TYPES = {
  REGULAR: 'regular',
  LOOTBOX: 'lootbox',
  TOURNAMENT: 'tournament',
};

export const HUNTING_SUB_TYPES = [HUNTING_TYPES.LOOTBOX, HUNTING_TYPES.TOURNAMENT];

// TODO: Move to templates/bets/constants
export const BET_TYPES = {
  ALL: 'all',
  SINGLE: 'single',
  COMBO: 'combo',
  SYSTEM: 'system',
  CUSTOM_BET: 'custom_bet',
};

export const EVENT_STATUS_TYPES = {
  ALL: 'all',
  LIVE: 'live',
  PREMATCH: 'not_started',
  MATCH_ABOUT_TO_START: 'match_about_to_start',
};

export const SEARCH_EVENT_STATUSES = [
  EVENT_STATUS_TYPES.PREMATCH,
  EVENT_STATUS_TYPES.MATCH_ABOUT_TO_START,
  EVENT_STATUS_TYPES.LIVE,
];

export const BONUS_EVENTS_TYPES = {
  EVENTS: 'events',
  COUNTRIES: 'countries',
};

export const VALIDITY_TYPES = {
  COMMON: 'common',
  INDIVIDUAL: 'individual',
};

export const DEPOSIT_AUDIENCE_TYPES = {
  ALL: 'all',
  NEW_PLAYER: 'new_player',
};

export const DEPOSIT_SUM_TYPES = {
  INDIVIDUAL: 'percent',
  FIXED: 'fixed',
};

export const STATUS_VARIANTS = {
  SETTLED: 'settled',
  CONFIRMED: 'confirmed',
};

export const STATUS_TYPES = {
  NO_RESULTS: 'no_results',
  WIN: 'win',
  LOSE: 'lose',
  REFUND: 'refund',
};

export const CURRENCY_CONFIG = {
  MIN_VALUE: 1,
  DECIMAL_DIGITS_COUNT: 2,
  CURRENCY: 'EUR',
  WIDTH: 160,
};

export const CRYPTO_CURRENCY_CONFIG = {
  MIN_VALUE: 1000,
  DECIMAL_DIGITS_COUNT: 5,
  MAX_CHARS_COUNT: 7,
  CURRENCY: 'BTC',
  WIDTH: 160,
};

export const ODDS_CONFIG = {
  MIN_VALUE: 1000,
  DECIMAL_DIGITS_COUNT: 2,
  WIDTH: 110,
};

export const SUBUNITS = {
  CURRENCY: 100,
  ODDS: 1000,
  CRYPTO: 100000000,
};

export const BONUS_FIELDS = {
  FREEBET_TYPE: 'freebetType',
  ID: 'name',
  NAME: 'displayName',
  BANNERS: 'banners',
  BANNERS_IS_ENABLED: 'banners.isEnabled',
  SHOW_IN_PROMOTION: 'showInPromotion',
  BET_TYPE: 'betType',
  SELECTION_FROM: 'selectionsFrom',
  MINIMUM_OUTCOME_ODD: 'minimumOutcomeOdd',
  MINIMUM_SELECTION_OUTCOME_ODD: 'minimumSelectionOdd',
  IS_MINIMUM_SELECTION_ODD_ACTIVE: 'isMinimumSelectionOddActive',
  IS_MINIMUM_ODD_ACTIVE: 'isMinimumOddActive',
  ODDS: 'bonusOdds',
  MINIMUM_ODD: 'minimumOdd',
  MAXIMUM_ODD: 'maximumOdd',
  MATCH_STATUS: 'matchStatus',
  ONLY_TOP_MARKETS: 'onlyTopMarkets',
  EVENT_TYPE: 'eventType',
  EVENT_CONDITIONS: 'eventConditions',
  COUNTRY_CODES: 'countryCodes',
  ISSUE_PERIOD: 'issuePeriod',
  ISSUE_PERIOD_IS_ON_SUBMIT: 'issuePeriod.isOnSubmit',
  ISSUE_PERIOD_FROM: 'issuePeriod.from',
  ISSUE_PERIOD_TO: 'issuePeriod.to',
  VALIDITY_TYPE: 'validityType',
  VALIDITY_PERIOD: 'validityPeriod',
  VALIDITY_PERIOD_IS_ON_SUBMIT: 'validityPeriod.isOnSubmit',
  VALIDITY_PERIOD_FROM: 'validityPeriod.from',
  VALIDITY_PERIOD_TO: 'validityPeriod.to',
  VALID_DAYS: 'validDays',
  IS_BOOST_ACTIVE: 'isBoostActive',
  BOOST: 'boost',
  LOOTBOX_BOOST: 'lootboxBoost',
  BOOST_MULTIPLIER: 'multiplier',
  BOOST_PROBABILITY: 'probability',
  TRIGGER_NAME: 'triggerName',
  IS_ALL_TAGS: 'isAllTags',
  TAGS: 'tags',
  IS_TAGS_EXCLUDED: 'isTagsExcluded',
  AUDIENCE_TYPE: 'audienceType',
  DAYS_AFTER_REGISTRATION: 'daysAfterRegistration',
  DEPOSIT_NUMBER: 'depositNumber',
  DEPOSIT_NUMBER_TYPE: 'isDepositNumberAfterRegistration',
  ONLY_VERIFIED: 'onlyVerified',
  COUNTRIES: 'countries',
  PLAYER_ID: 'playerId',
  IS_DEPOSIT_ACTIVE: 'isDepositActive',
  DEPOSIT_SUM: 'depositSum',
  DEPOSIT_PERIOD: 'depositPeriod',
  DEPOSIT_PERIOD_FROM: 'depositPeriod.from',
  DEPOSIT_PERIOD_TO: 'depositPeriod.to',
  PROMOCODE: 'promocode',
  PROMOCODE_QUANTITY: 'promocodeQuantity',
  IS_PROMOCODE_LIMITED: 'isPromocodeLimited',
  BONUS_SUM: 'bonusSum',
  FIAT_AMOUNT: 'bonusSum.fiatAmount',
  CRYPTO_AMOUNT: 'bonusSum.cryptoAmount',
  CUSTOM_AMOUNT: 'bonusSum.customAmount',
  POOL_AMOUNT: 'poolAmount',
  AFTER_MAKING_BETS: 'afterMakingBets',
  AMB_EVENT_TYPE: 'afterMakingBets.eventType',
  AMB_TRIGGER_EVENT_CONDITIONS: 'afterMakingBets.eventsDetails',
  AMB_TRIGGER_COUNTRY_CODES: 'afterMakingBets.eventsCountries',
  AMB_TRIGGER_EVENT_STATUS: 'afterMakingBets.matchStatus',
  AMB_TRIGGER_EVENT_MARKET: 'afterMakingBets.onlyTopMarkets',
  AMB_TRIGGER_BET_TYPE: 'afterMakingBets.betType',
  AMB_STATUS_VARIANT: 'afterMakingBets.statusVariant',
  AMB_BET_STATUS: 'afterMakingBets.betStatus',
  AMB_BET_MIN_ODD: 'afterMakingBets.betMinOdd',
  AMB_BET_MIN_STAKE: 'afterMakingBets.betMinStake',
  AMB_BET_MAX_STAKE: 'afterMakingBets.betMaxStake',
  AMB_SUM_CONFIGURATION: 'afterMakingBets.configuration',
  AMB_BONUS_SUM: 'afterMakingBets.bonusSum',
  AMB_TOTAL_BETS: 'afterMakingBets.totalBets',
  DEPOSIT_BONUS_SUM: 'depositBonusSum',
  BET_CURRENCY: 'betCurrency',
  DEPOSIT_BONUS_SUM_TYPE: 'depositBonusSum.type',
  DEPOSIT_BONUS_SUM_FIAT_MAX: 'depositBonusSum.fiatMax',
  DEPOSIT_BONUS_SUM_FIAT_AMOUNTS: 'depositBonusSum.fiatAmounts',
  DEPOSIT_BONUS_SUM_FIAT_SELECTED: 'depositBonusSum.isAllFiatSelected',
  DEPOSIT_BONUS_SUM_IS_CRYPTO_ACTIVE: 'depositBonusSum.isCryptoActive',
  DEPOSIT_BONUS_SUM_CRYPTO_MAX: 'depositBonusSum.cryptoMax',
  DEPOSIT_BONUS_SUM_CRYPTO_AMOUNTS: 'depositBonusSum.cryptoAmounts',
  DEPOSIT_BONUS_SUM_CRYPTO_SELECTED: 'depositBonusSum.isAllCryptoSelected',
  DEPOSIT_AUTOCONVERT: 'depositBonusSum.autoconvert',
  QUANTITY: 'quantity',
  NOTIFY_BY_EMAIL: 'notifyByEmail',
  ISSUE_POINTS: 'issuePoints',
  ISSUE_FOR_SAME_EVENT: 'issueForSameEvent',
  FARMING_EVENT_TYPE: 'farmingEventType',
  FARMING_EVENT_CONDITIONS: 'farmingEventConditions',
  FARMING_COUNTRY_CODES: 'farmingCountryCodes',
  FARMING_DAYS: 'farmingDays',
  AFFILIATE_TAG: 'affiliateTag',
  HUNTING_TYPE: 'huntingType',
  CONVERTED_VALUES: 'convertedValues',
  AUTOACTIVATE_ISSUED: 'autoactivateIssued',
  FREEBET_AUTOACTIVATE_ISSUED: 'freebetAutoactivateIssued',
};

export const BONUS_FIELD_LABELS = {
  [BONUS_FIELDS.FREEBET_TYPE]: 'bonus.freebetType.label',
  [BONUS_FIELDS.ID]: 'bonus.bonus-id',
  [BONUS_FIELDS.NAME]: 'bonus.bonus-name',
  [BONUS_FIELDS.BANNERS]: 'bonus.bonus-banners',
  PROMO_SETTINGS: 'bonus.promo-settings',
  [BONUS_FIELDS.SHOW_IN_PROMOTION]: 'bonus.show-in-promotions',
  [BONUS_FIELDS.BET_TYPE]: 'bonus.betType.label',
  [BONUS_FIELDS.SELECTION_FROM]: 'bonus.selections-from',
  SELECTION_FROM_HELPER: 'bonus.selections-from.from',
  [BONUS_FIELDS.MINIMUM_OUTCOME_ODD]: 'bonus.minimum-outcome-odd',
  [BONUS_FIELDS.MINIMUM_SELECTION_OUTCOME_ODD]: 'bonus.minimum-selection-outcome-odd',
  [BONUS_FIELDS.ODDS]: 'bonus.bonus-odds',
  ODDS_FROM: 'bonus.bonus-odds.from',
  ODDS_ODD: 'bonus.bonus-odds.odd',
  [BONUS_FIELDS.MINIMUM_ODD]: 'bonus.odds-min',
  [BONUS_FIELDS.MAXIMUM_ODD]: 'bonus.odds-max',
  [BONUS_FIELDS.MATCH_STATUS]: 'bonus.match-status',
  [BONUS_FIELDS.ONLY_TOP_MARKETS]: 'bonus.event-market',
  [BONUS_FIELDS.EVENT_TYPE]: 'bonus.bonus-target',
  [BONUS_FIELDS.AMB_EVENT_TYPE]: 'bonus.bonus-target.issuing',
  [BONUS_FIELDS.EVENT_CONDITIONS]: 'bonus.event-conditions',
  ALL_SPORT_TYPES: 'bonus.bonus-target.events.sport-types.all',
  ALL_SPORTS: 'bonus.bonus-target.events.sports.all',
  ALL_CATEGORIES: 'bonus.bonus-target.events.categories.all',
  ALL_TOURNAMENTS: 'bonus.bonus-target.events.tournaments.all',
  ALL_EVENTS: 'bonus.bonus-target.events.events.all',
  [BONUS_FIELDS.COUNTRY_CODES]: 'bonus.country-codes',
  ALL_COUNTRIES: 'bonus.bonus-target.countries.all',
  [BONUS_FIELDS.ISSUE_PERIOD]: 'bonus.issue-period',
  DATE_RANGE_FROM: 'bonus.date-range.from',
  DATE_RANGE_TO: 'bonus.date-range.to',
  [BONUS_FIELDS.VALIDITY_PERIOD]: 'bonus.validity-period',
  [BONUS_FIELDS.VALID_DAYS]: 'bonus.validity-period',
  VALID_DAYS_HELPER: 'bonus.valid-days',
  [BONUS_FIELDS.BOOST]: 'bonus.boost-data',
  [BONUS_FIELDS.LOOTBOX_BOOST]: 'bonus.boost-data.lootbox-boost',
  [BONUS_FIELDS.BOOST_MULTIPLIER]: 'bonus.boost-data.multiplier',
  [BONUS_FIELDS.BOOST_PROBABILITY]: 'bonus.boost-data.probability',
  [BONUS_FIELDS.TRIGGER_NAME]: 'bonus.triggerName.label',
  [BONUS_FIELDS.IS_ALL_TAGS]: 'bonus.bonus-tags.select-all',
  [BONUS_FIELDS.TAGS]: 'bonus.tags.label',
  TAGS_PLACEHOLDER: 'bonus.bonus-tags.placeholder',
  [BONUS_FIELDS.IS_TAGS_EXCLUDED]: 'bonus.exclude-tags',
  IS_TAGS_EXCLUDED_HELPER: 'bonus.exclude-tags.helper',
  [BONUS_FIELDS.AUDIENCE_TYPE]: 'bonus.deposit.audience',
  [BONUS_FIELDS.DAYS_AFTER_REGISTRATION]: 'bonus.deposit.deposit-days',
  DAYS_AFTER_REGISTRATION_HELPER: 'bonus.deposit.deposit-days.helper',
  [BONUS_FIELDS.DEPOSIT_NUMBER]: 'bonus.deposit.deposit-number',
  DEPOSIT_NUMBER_HELPER: 'bonus.deposit.deposit-number.types.number.helper',
  [BONUS_FIELDS.DEPOSIT_NUMBER_TYPE]: 'bonus.deposit.deposit-number-type',
  [BONUS_FIELDS.ONLY_VERIFIED]: 'bonus.only-verified',
  [BONUS_FIELDS.COUNTRIES]: 'bonus.countries.label',
  [BONUS_FIELDS.PLAYER_ID]: 'bonus.playerId.label',
  PLAYER_ID_HELPER: 'bonus.player-ids.helper',
  [BONUS_FIELDS.IS_DEPOSIT_ACTIVE]: 'bonus.bonus-sum.amount-of-deposits',
  [BONUS_FIELDS.DEPOSIT_SUM]: 'bonus.deposit-sum',
  [BONUS_FIELDS.DEPOSIT_PERIOD]: 'bonus.deposit-period',
  [BONUS_FIELDS.PROMOCODE]: 'bonus.promocode',
  [BONUS_FIELDS.PROMOCODE_QUANTITY]: 'bonus.promocode-quantity',
  BONUS_SUM: 'bonus.bonus-sum',
  BONUS_SUM_PER_PLAYER: 'bonus.bonus-sum.per-player',
  BONUS_SUM_TOURNAMENT_POOL: 'bonus.bonus-sum.tournament-pool',
  [BONUS_FIELDS.FIAT_AMOUNT]: 'bonus.bonus-sum.fiat',
  [BONUS_FIELDS.CRYPTO_AMOUNT]: 'bonus.bonus-sum.crypto',
  [BONUS_FIELDS.POOL_AMOUNT]: 'bonus.bonus-sum.tournament-pool',
  PROMOCODE_QUANTITY_HELPER: 'bonus.promocode-quantity.helper',
  [BONUS_FIELDS.AMB_BET_STATUS]: 'bonus.bet-status',
  [BONUS_FIELDS.AMB_BET_MIN_ODD]: 'bonus.bet-min-odd',
  [BONUS_FIELDS.AMB_BET_MIN_STAKE]: 'bonus.bet-min-stake',
  [BONUS_FIELDS.AMB_BET_MAX_STAKE]: 'bonus.bet-max-stake',
  [BONUS_FIELDS.AMB_SUM_CONFIGURATION]: 'bonus.after-making-bets.bonus-sum',
  [BONUS_FIELDS.AMB_TOTAL_BETS]: 'bonus.after-making-bets.bonus-sum.total-bets',
  AMB_QUANTITY: 'bonus.after-making-bets.bonus-sum.quantity',
  AMB_FIAT_AMOUNT: 'bonus.after-making-bets.bonus-sum.sum',
  AMB_CRYPTO_AMOUNT: 'bonus.after-making-bets.bonus-sum.sum',
  [BONUS_FIELDS.DEPOSIT_BONUS_SUM_TYPE]: 'bonus.bonus-sum',
  DEPOSIT_BONUS_SUM_FIAT: 'bonus.deposit.bonus-sum.fiat',
  DEPOSIT_BONUS_AUDIENCE_TYPE_ALL: 'bonus.deposit.audience.types.all',
  DEPOSIT_BONUS_AUDIENCE_TYPE_NEW_PLAYER: 'bonus.deposit.audience.types.new-player',
  [BONUS_FIELDS.DEPOSIT_BONUS_SUM_FIAT_MAX]: 'bonus.deposit.bonus-sum.range-max-title',
  DEPOSIT_BONUS_SUM_CRYPTO: 'bonus.deposit.bonus-sum.crypto',
  [BONUS_FIELDS.DEPOSIT_BONUS_SUM_CRYPTO_MAX]: 'bonus.deposit.bonus-sum.range-max-title',
  DEPOSIT_BONUS_SUM_FROM: 'bonus.deposit.bonus-sum.from',
  DEPOSIT_BONUS_SUM_TO: 'bonus.deposit.bonus-sum.to',
  DEPOSIT_AUTOCONVERT: 'bonus.deposit.bonus-sum.autoconvert',
  [BONUS_FIELDS.QUANTITY]: 'bonus.quantity',
  QUANTITY_HELPER: 'bonus.quantity.helper',
  [BONUS_FIELDS.NOTIFY_BY_EMAIL]: 'bonus.notify-by-email',
  [BONUS_FIELDS.ISSUE_POINTS]: 'bonus.issue-points',
  ISSUE_POINTS_WIN: 'bonus.issue-points.win',
  ISSUE_POINTS_LOSE: 'bonus.issue-points.lose',
  [BONUS_FIELDS.ISSUE_FOR_SAME_EVENT]: 'bonus.issue-for-same-event',
  [BONUS_FIELDS.FARMING_EVENT_TYPE]: 'bonus.farming-event-type',
  [BONUS_FIELDS.FARMING_EVENT_CONDITIONS]: 'bonus.farming-event-conditions',
  [BONUS_FIELDS.FARMING_COUNTRY_CODES]: 'bonus.farming-country-codes',
  [BONUS_FIELDS.FARMING_DAYS]: 'bonus.farming-period',
  FARMING_DAYS_HELPER: 'bonus.farming-days',
  [BONUS_FIELDS.AFFILIATE_TAG]: 'bonus.affiliate-tag',
  LOOTBOX_BOOST_ITEM: 'bonus.lootbox.boost-item',
  [BONUS_FIELDS.AMB_TRIGGER_BET_TYPE]: 'bonus.after-making-bets.bet-type',
  AUTOACTIVATE_ISSUED_TITLE: 'bonus.autoactivate-title',
  [BONUS_FIELDS.AUTOACTIVATE_ISSUED]: 'bonus.autoactivate-description',
  [BONUS_FIELDS.FREEBET_AUTOACTIVATE_ISSUED]: 'bonus.autoactivate-description',
  AUTOACTIVATE_ISSUED_HINT: 'bonus.autoactivate-hint',
};

export const MIN_SEARCH_SYMBOLS_LENGTH = 3;

export const STATISTIC_TABS_IDS = {
  MAIN: 'main',
  TOP10: 'top10',
  COUNTRY: 'country',
  SPORT: 'sport',
  BET_TYPE: 'betType',
  DEVICE: 'device',
  CURRENCY: 'currency',
  GROUP: 'group',
  STAG: 'stag',
  PROJECT: 'project',
};

export const TEAM_FIELDS = {
  NAME: BONUS_FIELDS.ID,
  RULE_NAME: BONUS_FIELDS.TRIGGER_NAME,
  BET_TYPE: BONUS_FIELDS.BET_TYPE,
  MINIMUM_ODD: BONUS_FIELDS.MINIMUM_ODD,
  MAXIMUM_ODD: BONUS_FIELDS.MAXIMUM_ODD,
  MATCH_STATUS: BONUS_FIELDS.MATCH_STATUS,
  ONLY_TOP_MARKETS: BONUS_FIELDS.ONLY_TOP_MARKETS,
  EVENT_TYPE: BONUS_FIELDS.EVENT_TYPE,
  STAKE_MIN: 'stakeMin',
  STAKE_MAX: 'stakeMax',
  EVENT_CONDITIONS: BONUS_FIELDS.EVENT_CONDITIONS,
  IS_ALL_TAGS: BONUS_FIELDS.IS_ALL_TAGS,
  TAGS: BONUS_FIELDS.TAGS,
  PROMOCODE: BONUS_FIELDS.PROMOCODE,
  IS_TAGS_EXCLUDED: BONUS_FIELDS.IS_TAGS_EXCLUDED,
};

export const TEAM_FIELDS_LABELS = {
  [TEAM_FIELDS.NAME]: 'new-jackpot-team.team-name',
  [TEAM_FIELDS.RULE_NAME]: 'new-jackpot-team.team-rule',
  STAKE: 'new-jackpot-team.stake',
  [TEAM_FIELDS.TAGS]: 'new-jackpot-team.player-tags',
  [TEAM_FIELDS.EVENT_CONDITIONS]: 'new-jackpot-team.event-conditions',
  [TEAM_FIELDS.IS_TAGS_EXCLUDED]: 'new-jackpot-team.tags-excluded.description',
};

export const RULE_NAMES = {
  HAS_GROUP: 'has_group',
  PROMOCODE: 'promocode',
};

export const BONUS_FIELD_TOOLTIPS = {
  DEFAULT_BONUS_TOOLTIP: 'bonus.bonus-sum.tooltip',
  FREEBET_BONUS_TOOLTIP: 'bonus.bonus-sum.tooltip-freebet',
};

// TODO: Move to templates/bets/constants
export const BET_STATUSES = {
  CASHED_OUT: 'cashed_out',
  WITHDRAWN: 'withdrawn',
  WAITING_RESULTS: 'waiting_results',
  READY_TO_PROCESS: 'ready_to_process',
  DONE: 'done',
  CANCELLED: 'cancelled',
  CASHOUT_IN_PROGRESS: 'cashout_in_progress',
  SUBMITTED: 'submitted',
  REJECTED: 'rejected',
};

// TODO: Move to templates/bets/constants
export const BET_SELECTION_STATUSES = {
  NO_RESULTS: 'no_results',
  WIN_ENTIRE: 'win_entire',
  LOSE_ENTIRE: 'lose_entire',
  REFUND_ENTIRE: 'refund_entire',
  REFUND_HALF_WIN_OTHER: 'refund_half_win_other',
  REFUND_HALF_LOSE_OTHER: 'refund_half_lose_other',
  CANCELED: 'canceled',
};

export const PRODUCER_TYPE = {
  LIVE: 'live',
  PREMATCH: 'prematch',
  MIXED: 'mixed',
};

export const MAX_SEARCH_LENGTH = 50;

export const DESKTOP_IMAGE_RESTRICTIONS = { width: 1200, height: 600 };
export const MOBILE_IMAGE_RESTRICTIONS = { width: 720, height: 600 };

export const PROVIDERS = {
  BETCONSTRUCT: 'betconstruct',
  ODDIN: 'oddin',
  BETRADAR: 'betradar',
};

export const PROVIDER_BY_CODE = {
  bc: 'betconstruct',
  od: 'oddin',
  sr: 'betradar',
};

export const SPORT_TYPES = {
  REGULAR: 'regular',
  CYBER: 'cyber',
};

export const BONUS_STATUSES = {
  AVAILABLE: 'available',
  ACTIVE: 'active',
  NOT_AVAILABLE: 'not_available',
  DEACTIVATED: 'deactivated',
  BONUS_OVER: 'bonus_over',
  EXPIRED: 'expired',
  OVERRIDDEN: 'overridden',
  UNKNOWN: 'unknown',
};

export const EURO = {
  code: 'EUR',
  subunits: 100,
  isFiat: true,
  name: 'Euro',
};

export const BITCOIN = {
  code: 'BTC',
  subunits: 100000000,
  isFiat: false,
  name: 'Bitcoin',
};

export const PIE_CHART_COLORS_PALETTE = [
  '#5E9FE0',
  '#EFDC32',
  '#FF8A65',
  '#B39DDB',
  '#AED581',
  '#F57C00',
  '#81C784',
  '#07B178',
  '#20DA6F',
  '#B0BEC5',
];

export const REPORT_TYPES = {
  BETS: 'bets',
  TURFSPORT_BETS: 'turfsport_bets',
  EVENTS: 'events',
};

export const REPORT_STATUSES = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  FAILED: 'failed',
  COMPLETED: 'completed',
  EXPIRED: 'expired',
};

export const SECTIONS = {
  AUDIT: 'audit',
  BETS: 'bets',
  BONUS_STATISTIC: 'bonus_statistic',
  BONUSES: 'bonuses',
  CMS: 'cms',
  EVENTS: 'events',
  EXPORTS: 'exports',
  JACKPOT_HITS: 'jackpot_hits',
  JACKPOT_TEAMS: 'jackpot_teams',
  PLAYERS_HUB: 'players_hub',
  PLAYERS: 'players',
  PROJECT_SETTINGS: 'project_settings',
  PROJECTS: 'projects',
  ROLES: 'roles',
  STATISTIC: 'statistic',
};

export const UNKNOWN_COUNTRY = 'unknown';

export const GTM_EVENT_CATEGORIES = {
  PAGE: 'Page',
  FILTERS_PANEL: 'Filters panel',
};

export const GTM_EVENT_PAGES = {
  AUDIT: 'audit',
  BETS: 'bets',
  BONUS_STATISTICS: 'bonus_statistics',
  BONUSES: 'bonuses',
  EVENTS: 'events',
  PLAYER_ANALYTICS: 'player_analytics',
  PLAYER_DETAILS: 'player_details',
  STATISTICS: 'statistics',
  USERS: 'users',
};

export const TABLE_DENSITY = {
  COMPACT: 'compact',
  STANDARD: 'standard',
  COMFORTABLE: 'comfortable',
};

export const PROFILES_MERGED_BY = {
  AUTO: 'auto',
  MANUAL: 'manual',
};

export const DATA_PROVIDERS = {
  SPORTSBOOK: 'sportsbook',
  TURFSPORT: 'turfsprot',
};

export const PROJECT_CUSTOMIZATION = {
  mzansibet: {
    freebetAfterMakingBetStakeCurrencyCode: 'ZAR',
  },
  sportsbook_mzansibet_test: {
    freebetAfterMakingBetStakeCurrencyCode: 'ZAR',
  },
  gli1933: {
    isSouthAfricaLicense: true,
  },
};
