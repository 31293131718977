import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Image from 'next/image';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { Text, t } from 'components/formatted-message';
import { HTTP_CODES } from 'utils/constants';

const SUPPORTED_ERROR_CODES = [
  HTTP_CODES.BAD_REQUEST,
  HTTP_CODES.FORBIDDEN,
  HTTP_CODES.NOT_FOUND,
  HTTP_CODES.INTERNAL_SERVER_ERROR,
];

export function ErrorTemplate({ errorCode, onReset }) {
  const router = useRouter();

  const handleClick = () => {
    onReset?.();
    router.push('/');
  };

  return (
    <Stack
      alignItems="center"
      direction="column"
      justifyContent="center"
      spacing={6}
      sx={{ height: '100%', p: 4 }}>
      {SUPPORTED_ERROR_CODES.includes(errorCode) && (
        <Image alt="" height={260} src={`/${errorCode}_bg.png`} width={596} />
      )}
      <Text
        align="center"
        id={
          SUPPORTED_ERROR_CODES.includes(errorCode)
            ? `error-template.error.${errorCode}`
            : 'error-template.error.general'
        }
        sx={{ typography: { xxs: 'h6', sm: 'h3' } }}
      />
      <Button variant="contained" onClick={handleClick}>{t`error-template.back-home`}</Button>
    </Stack>
  );
}

ErrorTemplate.propTypes = {
  errorCode: PropTypes.number,
  onReset: PropTypes.func,
};

ErrorTemplate.defaultProps = {
  errorCode: HTTP_CODES.INTERNAL_SERVER_ERROR,
  onReset: undefined,
};
