import isPlainObject from 'lodash.isplainobject';

export function omitEmptyValues(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    const current = obj[key];

    if (current === undefined) return acc;

    if (isPlainObject(current)) {
      const nested = omitEmptyValues(current);

      if (Object.keys(nested).length === 0) return acc;

      return { ...acc, [key]: nested };
    }

    return { ...acc, [key]: current };
  }, {});
}
